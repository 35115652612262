import React from 'react';
import styled from 'styled-components';
import { RecommendationsSection } from 'views/home/recommendations-section';
import { Offer } from 'views/offer';
import { Content } from 'views/offer/components/Content';
import { Header } from 'views/offer/components/Header';
import { Image } from 'views/offer/components/Image';
import { ImageWrapper } from 'views/offer/components/ImageWrapper';
import { List } from 'views/offer/components/List';
import { ListItem } from 'views/offer/components/ListItem';
import { Paragraph } from 'views/offer/components/Paragraph';
import { Section } from 'views/offer/components/Section';
import { Subtitle } from 'views/offer/components/Subtitle';
import { getOfferBreadcrumbsLinks } from 'views/offer/helpers';
import { SEO } from 'components/seo';
import { TAGS } from 'constants/tags';
import { graphql, useStaticQuery } from 'gatsby';
import OtherTreatmentsJson from 'jsons/otherTreatment.json';
import { Layout } from 'layouts';

const SImageWrapper = styled(ImageWrapper)`
  max-width: 1920px;
`;

const Offer11 = ({ location }) => {
  const { pathname } = location;
  const breadcrumbsLinks = getOfferBreadcrumbsLinks(pathname);

  const images = useStaticQuery(graphql`
    query {
      sculptra1: file(relativePath: { eq: "sculptra1.png" }) {
        childImageSharp {
          gatsbyImageData(width: 500, quality: 100, formats: [WEBP])
        }
      }
      sculptra2: file(relativePath: { eq: "sculptra2.png" }) {
        childImageSharp {
          gatsbyImageData(width: 500, quality: 100, formats: [WEBP])
        }
      }
      sculptra3: file(relativePath: { eq: "sculptra3.png" }) {
        childImageSharp {
          gatsbyImageData(width: 500, quality: 100, formats: [WEBP])
        }
      }
      sculptra4: file(relativePath: { eq: "sculptra4.png" }) {
        childImageSharp {
          gatsbyImageData(width: 500, quality: 100, formats: [WEBP])
        }
      }
      fourth: file(relativePath: { eq: "loyalty.png" }) {
        childImageSharp {
          gatsbyImageData(width: 750, quality: 100, formats: [WEBP])
        }
      }
    }
  `);
  return (
    <Layout>
      <Offer otherTreatments={OtherTreatmentsJson.niciLiftingujace}>
        <Header breadcrumbsLinks={breadcrumbsLinks}>
          Zabieg z kwasem polimlekowym - silnym stymulatorem skóry
        </Header>
        <Content>
          <Section>
            <SImageWrapper>
              <Image
                src={images.fourth.childImageSharp}
                alt="kwas polimlekowy sculptra ideale medycyna estetyczna warszawa wola"
              />
            </SImageWrapper>
            <Subtitle>Czym jest kwas polimlekowy?</Subtitle>
            <Paragraph>
              <b>Kwas polimlekowy</b>, znany również jako poli(L-kwas mlekowy),
              to biodegradowalny i biokompatybilny polimer, który znalazł
              szerokie zastosowanie w medycynie estetycznej jako rewolucyjny
              składnik zabiegów odmładzających i regenerujących skórę.
              Wykorzystywany głównie w formie wstrzykiwań, kwas polimlekowy
              działa poprzez stymulację naturalnej produkcji kolagenu w skórze,
              co prowadzi do stopniowej poprawy jej struktury, napięcia i
              ogólnej jędrności. Zabieg ten, będąc niechirurgiczną metodą
              odmładzania, jest odpowiedzią na rosnące zapotrzebowanie na
              efektywne, ale jednocześnie bezpieczne i mało inwazyjne sposoby
              poprawy wyglądu skóry.
            </Paragraph>
            <Paragraph>
              W przeciwieństwie do bezpośredniego wypełniania zmarszczek, jak ma
              to miejsce w przypadku innych wypełniaczy, kwas polimlekowy działa
              długofalowo, co oznacza, że efekty zabiegu rozwijają się stopniowo
              i mogą być widoczne nawet po kilku miesiącach od iniekcji. Dzięki
              temu skóra nie tylko wygląda na młodszą i bardziej promienną, ale
              również staje się zdrowsza i bardziej elastyczna na poziomie
              komórkowym. Kwas polimlekowy jest szczególnie polecany dla osób
              poszukujących naturalnie wyglądających efektów oraz długotrwałej
              poprawy kondycji skóry.
            </Paragraph>
            <Subtitle>Wskazania do użucia kwasu polimlekowego</Subtitle>
            <List>
              <ListItem>Redukcja zmarszczek, bruzd, ubytków w skórze</ListItem>
              <ListItem>Lifting skóry twarzy</ListItem>
              <ListItem>Utrata jędrności skóry</ListItem>
              <ListItem>Lipoatrofia</ListItem>
              <ListItem>Korekcja braków objętości</ListItem>
            </List>
          </Section>
          <Section>
            <Subtitle>Opis zabiegu zabiegu kwasem polimlekowym</Subtitle>
            <Paragraph>
              Zabieg zawsze poprzedzony jest indywidualną konsultacją lekarza
              medycyny estetycznej. W zależności od wskazań, kwas polimlekowy
              podawany jest przy użyciu igły lub kaniuli. Na zakończenie zabiegu
              wykonuje się masaż, pozwalający na równomierne rozprowadzenie
              preparatu pod skórą. Masaż musi być powtarzany przez 2 tygodnie co
              najmniej 2 razy dziennie. Procedurę wykonuje się w miejscu
              wcześniej znieczulonym przy pomocy specjalnego kremu. Zabieg nie
              wymaga rekonwalescencji.
            </Paragraph>
            <Subtitle>Efekty zabiegu</Subtitle>
            <List>
              <ListItem>
                Długotrwała regeneracja skóry – powoduje produkcję kolagenu w
                skórze
              </ListItem>
              <ListItem>
                Gładka, odmłodzona twarz – natychmiastowe wyrównywanie nawet
                głębokich zmarszczek
              </ListItem>
              <ListItem>
                Jędrne policzki i wyraźny owal twarzy – dzięki uruchomieniu
                głębokich procesów odbudowy skóry
              </ListItem>
              <ListItem>
                Naturalny efekt – procesy odbudowy skóry stymulowane przez wiele
                miesięcy
              </ListItem>
            </List>
            <ImageWrapper>
              <Image
                src={images.sculptra1.childImageSharp}
                alt="sculptra kwas polimlekowy przed po"
              />
              <Image
                src={images.sculptra2.childImageSharp}
                alt="sculptra przed po"
              />
              <Image
                src={images.sculptra3.childImageSharp}
                alt="kwas polimlekowy przed po"
              />
              <Image
                src={images.sculptra4.childImageSharp}
                alt="stymulator sculptra kwas polimlekowy przed po"
              />
            </ImageWrapper>
          </Section>
          <Section>
            <Subtitle>Po jakim czasie i jak długo widoczne są efekty?</Subtitle>
            <Paragraph>
              Pierwsze efekty - wypełnienie zmarszczek, bruzd są widoczne tuż po
              zabiegu. Efekty wynikające z wytworzenia nowych włókien
              kolagenowych- lifting, wzrost jędrności skóry pojawiają się ok. 6
              tygodni po zabiegu. Po tym czasie pacjent zgłasza się do gabinetu
              i po badaniu skóry oceniamy czy warto wykonać jeszcze jeden
              zabieg. W zależności od stanu wyjściowego skóry oraz oczekiwań
              zaleca się wykonanie 1-4 zabiegów w odstępach 6 tygodniowych.{' '}
              <b>
                Efekty zabiegu są dlugotrwałe- powtarzamy go nie wcześniej niż
                po upływie 2-óch lat, a efekty widoczne są nawet 3 lata.
              </b>
            </Paragraph>
          </Section>
          <RecommendationsSection />
          <Section>
            <Subtitle>Przeciwwskazania</Subtitle>
            <List>
              <ListItem>Okres ciąży, połogu i karmienia piersią</ListItem>
              <ListItem>
                Choroby autoimmunologiczne (np. choroba Hashimoto)
              </ListItem>
              <ListItem>Choroby tkanki łącznej</ListItem>
              <ListItem>Zaburzenia krzepnięcia</ListItem>
              <ListItem>Miejscowy stan zapalny skóry</ListItem>
              <ListItem>Choroby nowotworowe</ListItem>
              <ListItem>Niewyrównana cukrzyca</ListItem>
              <ListItem>
                Skłonność do powstawania blizn przerostowych, keloidów
              </ListItem>
            </List>
          </Section>
          <Section>
            <Subtitle>Dlaczego IDEALE?</Subtitle>
            <Paragraph>
              Zapraszamy do <b>IDEALE Medycyna Estetyczna, Wola, Warszawa</b>,
              gdzie odzyskasz swoją pewność siebie w przyjemnej atmosferze. Nasz
              doświadczony lekarz medycyny estetycznej indywidualnie dopasuje
              odpowiednie zabiegi dla Ciebie, dzięki którym wyjdziesz od nas
              piękna, naturalna i promienna. Nasza klinika mieści się w samym
              sercu Woli, zapewniając łatwy dostęp dla naszych klientów.
            </Paragraph>
            <Paragraph>
              W IDEALE skupiamy się na dostarczaniu najskuteczniejszych zabiegów
              medycyny estetycznej, które czerpiemy prosto z{' '}
              <b>międzynarodowych kongresów i szkoleń</b>. Dążymy do zapewnienia
              najwyższej jakości usług, a nasz zespół stale poszerza swoją
              wiedzę i umiejętności, aby sprostać Twoim oczekiwaniom.
            </Paragraph>
            <Paragraph>
              Razem z nami odkryjesz potencjał swojej urody i poczujesz się w
              pełni zadbana i zrelaksowana. Zaufaj naszemu doświadczeniu i
              profesjonalizmowi, abyś mógł cieszyć się pięknem, jakie tkwi w
              Tobie.
            </Paragraph>
            <Paragraph>
              IDEALE, Gabinet Medycyny Estetycznej, ul. Sokołowska 9, Wola,
              Warszawa.
            </Paragraph>
          </Section>
        </Content>
      </Offer>
    </Layout>
  );
};

export default Offer11;

export const Head = () => <SEO tags={TAGS.sculptra} />;
